<template>
  <div>
    <strong class="my-2">{{name}}</strong>
    <div :id="id" :style="`width: ${width}; height: ${height};`"></div>
    <!--
    <div>
      <b-button @click="getCoordinates">Get coordinates</b-button>
    </div>
    -->
    <div v-if="nodesString">
      {{ nodesString }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['nodes', 'links', 'categories', 'layout', 'name', 'width', 'height'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      chart: null,
      id: uuid(),
      nodesString: false
    }
  },
  methods: {
    getCoordinates: function () {
      const nodes = []
      console.log('triggered getCoordinates')
      const options = this.chart.getOption()
      console.log('options')
      console.log(options)
      const series = this.chart.getModel().getSeries()
      const serie = series[0]
      const data = serie.getData()
      console.log(data)
      console.log(data._idList)
      console.log(data._itemLayouts)
      _.each(data._idList, function (id, index) {
        const node = {
          id: id,
          x: data._itemLayouts[index][0],
          y: data._itemLayouts[index][1]
        }
        nodes.push(node)
      })
      console.log('nodes')
      console.log(nodes)
      this.nodesString = JSON.stringify(nodes)

      /*
      const series = this.chart.getModel().getSeries()
      series.forEach(function (serie) {
        const row = serie.getData().mapArray(['x', 'y'])
        coordinates.push(row)
      })
      */
    },
    load: async function () {
      const options = {
        tooltip: {},
        legend: [
          {
            data: this.categories.map(function (a) {
              return a.name
            })
          }
        ],
        series: [
          {
            type: 'graph',
            layout: this.layout ? this.layout : 'force',
            symbolSize: 5,
            data: this.nodes,
            links: this.links,
            nodeScaleRatio: 0.6,
            categories: this.categories,
            roam: true,
            label: {
              position: 'right'
            },
            force: {
              repulsion: 100
            },
            zoom: 1
          }
        ]
      }
      await delay(10)
      this.chart = echarts.init(document.getElementById(this.id), 'kpmg')
      this.chart.setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'nodes': 'reload',
    'links': 'reload',
    'categories': 'reload',
    'layout': 'reload',
    'name': 'reload'
  }
}
</script>

<style>
</style>

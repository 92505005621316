<template>
<div>
  <b-container fluid>
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Data graphs</h1>
        <p>Explore data graphs for elements pre-positioned using force-directed layout. You can use filters to limit visible elements. Using filters does not change position of elements. Last position and elements update as at 1 May 2024. </p>
      </b-col>
    </b-row>
    <b-row v-if="showGraph" class="bg-white shadow py-4">
      <b-col>
        <div class="mb-4" style="display: flex; height: 30px;">
          <filter-interface class="mr-4" namespace="metrics" :rows="metrics" v-model="metricsFiltered" :staticDataProperties="staticDataProperties" :includeTaxonomy="true"></filter-interface>
        </div>
        <div>
          <graph-simple :nodes="nodes" :links="links" :categories="categories" :layout="'none'" :name="'Metrics and data elements graph'" :width="'100%'" :height="'100vh'"></graph-simple>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import FilterInterface from '@/components/FilterInterfaceV4.vue'
import GraphSimple from '@/components/charts/v1/graphPositions.vue'
import positions from '@/views/metrics/graph.js'
export default {
  name: 'MetricsGraphs',
  components: {
    FilterInterface,
    GraphSimple
  },
  computed: {
    dataelements: function () {
      let result = 8
      if (this.$store.state.dataelements > 8) {
        result = this.$store.state.dataelements
      }
      return result
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      categories: [
        {
          name: 'Metrics'
        },
        {
          name: 'Data Elements'
        },
        {
          name: 'Reporting Frameworks'
        }
      ],
      links: [],
      metricsFiltered: [],
      nodes: [],
      showDataElements: true,
      showGraph: false,
      staticDataProperties: [
        { name: 'Reporting Frameworks', property: 'reportingframeworks', graph: 'reportingframeworks' }
      ]
    }
  },
  created: function () {
    console.log(this.metrics)
    this.buildGraph()
  },
  methods: {
    buildGraph: function () {
      this.metricsFiltered.forEach(metric => {
        this.nodes.push({
          id: 'metric-' + metric.id,
          name: metric.name,
          category: 0
        })
        if (metric.dataelements.length > 0 && this.showDataElements) {
          metric.dataelements.forEach(element => {
            this.links.push({
              source: 'metric-' + metric.id,
              target: 'dataelement-' + element.id,
              uuid: 'metric-' + metric.id + 'dataelement-' + element.id
            })
            this.nodes.push({
              id: 'dataelement-' + element.id,
              name: element.name,
              category: 1
            })
          })
        }
        if (metric.reportingframeworks.length > 0) {
          metric.reportingframeworks.forEach(framework => {
            this.links.push({
              target: 'metric-' + metric.id,
              source: 'framework-' + framework.id,
              uuid: 'metric-' + metric.id + 'framework-' + framework.id
            })
            this.nodes.push({
              id: 'framework-' + framework.id,
              name: framework.name,
              symbol: 'diamond',
              symbolSize: 15,
              category: 2
            })
          })
        }
      })
      this.nodes = _.uniqBy(this.nodes, 'id')
      this.links = _.uniqBy(this.links, 'uuid')
      console.log('positions')
      console.log(positions)
      console.log('nodes')
      console.log(this.nodes)
      const existingPositions = _.intersectionBy(positions, this.nodes, 'id')
      const existingNodes = _.intersectionBy(this.nodes, existingPositions, 'id')
      const combinedArray = [...existingNodes, ...existingPositions]
      const groupedById = _.groupBy(combinedArray, 'id')
      const mergedByProperties = _.map(groupedById, (group) => {
        return _.mergeWith({}, ...group)
      })
      this.nodes = mergedByProperties
      console.log('new nodes')
      console.log(this.nodes)
      this.showGraph = true
    }
  },
  watch: {
    metricsFiltered: function () {
      this.showGraph = false
      this.nodes = []
      this.links = []
      this.buildGraph()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
